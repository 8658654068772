import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Form from "../components/form/form"

class IndexPage extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = this.props.data.site.siteMetadata.description

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Home" description={siteDescription} />
        <div className="hero-section">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <h1 className="display-2">Breathe Again</h1>
                <h2 className="text-uppercase font-weight-light">
                  Bereavement Doula
                </h2>
                <p className="lead pt-4">
                  Understanding the bereaved Mother and helping her to get to
                  the Mother that grieves.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div id="my-story" className="my-story my-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-7">
                <div className="px-3 px-md-5 py-5 bg-dark h-100 text-white">
                  <h2 className="font-italic font-serif display-5">
                    When nobody else understands, I do!
                  </h2>
                  <h5 className="text-uppercase font-weight-bold">My Story</h5>
                  <p className="py-4">
                    I am a mother of 3 sons. I lost my middle son in January of
                    2015. At 39 weeks 6 days I went in for a scheduled c-section
                    and my baby no longer had a heart beat. This experience
                    shook me to my core but I find healing in helping other
                    Mothers get through such a horrific tragedy. I want to show
                    these women that they can breathe again when they feel like
                    they never will.
                  </p>
                </div>
              </div>
              <div className="col-md-5 pl-md-0">
                <div className="bg" />
              </div>
            </div>
          </div>
        </div>
        <div id="services" className="services">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <h2 className="text-center font-italic font-serif text-white display-5">
                  Experienced Support
                </h2>
                <p className="lead text-center text-white-50 pb-5">
                  Equipping the bereaved Mother to breathe again, by Virtuu of
                  understanding, support, encouragement and honor
                </p>
              </div>
            </div>
            <div className="row justify-content-around pt-5">
              <div className="col-md-5">
                <div className="p-5 bg-white h-100">
                  <div className="icon">
                    <i className="fa fa-heart-o" />
                  </div>
                  <div className="pt-4">
                    <h4 className="text-uppercase font-weight-bold text-center text-primary">
                      Doula Services
                    </h4>
                    <div className="pt-4">
                      <p>
                        Providing physical, emotional, and informational support
                      </p>
                      <ul>
                        <li>Before birth</li>
                        <li>During birth</li>
                        <li>Immediately after birth</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="p-5 bg-white h-100">
                  <div className="icon">
                    <i className="fa fa-heart" />
                  </div>
                  <div className="pt-4">
                    <h4 className="text-uppercase font-weight-bold text-center text-primary">
                      Breathe Again Support
                    </h4>
                    <div className="pt-4">
                      <ul>
                        <li>In-depth follow-up</li>
                        <li>Walks</li>
                        <li>Retreats</li>
                        <li>Resources / Connections</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="how-you-can-help my-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-5 pr-md-0">
                <div className="bg" />
              </div>
              <div className="col-md-7">
                <div className="px-3 px-md-5 py-5 bg-dark h-100 text-white">
                  <h2 className="font-italic font-serif">
                    1 in 4 women experience pregnancy or infant loss.
                  </h2>
                  <h5 className="text-uppercase font-weight-bold">
                    How You Can Help
                  </h5>
                  <p className="pt-4">
                    If you ever come across somebody facing that tragedy, lead
                    them to the person who understands.
                  </p>
                  <p className="pb-4">
                    I have certification to be able to be there through the
                    birth of a miscarried or stillborn child. I will provide
                    support and opportunities to honor their child, and be
                    honored after the birth.
                  </p>
                  <Link
                    className="btn btn-primary"
                    role="button"
                    to="/#contact"
                  >
                    Get in Touch
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="contact" className="contact-section bg-light my-3">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-10 text-center">
                <h2 className="text-center font-italic font-serif">
                  "To all who mourn in Israel, he will give a crown of beauty
                  for ashes, a joyous blessing instead of mourning, festive
                  praise instead of despair. In their righteousness, they will
                  be like great oaks that the Lord has planted for his own
                  glory."
                </h2>
                <p className="font-serif text-uppercase text-muted">
                  Isaiah‬ ‭61:3‬ ‭NLT
                </p>
              </div>
            </div>

            <h4 className="lead pt-4 pb-5 text-center">
              Connect with us by filling up the form below, or reach us through
              the following:
            </h4>
            <div className="row justify-content-center">
              <div className="col-lg-5 mb-3">
                <div className="contact-icon phone">
                  <span className="d-block font-serif font-italic">Phone</span>
                  <a
                    className="display-6 font-weight-bold"
                    href="tel:1-719-460-9991"
                  >
                    719-460-9991
                  </a>
                </div>
              </div>
              <div className="col-lg-5 mb-3">
                <div className="contact-icon facebook">
                  <span className="d-block font-serif font-italic">
                    Facebook
                  </span>
                  <a
                    className="display-6 font-weight-bold"
                    href="https://www.facebook.com/Breathe-Again-Bereavement-Doula-105347920808436/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Breathe Again Bereavement Doula
                  </a>
                </div>
              </div>
              <div className="col-lg-5 mb-3">
                <div className="contact-icon email">
                  <span className="d-block font-serif font-italic">Email</span>
                  <a
                    className="display-6 font-weight-bold"
                    href="mailto:breatheagain0130@gmail.com"
                  >
                    breatheagain0130@gmail.com
                  </a>
                </div>
              </div>
              <div className="col-lg-5 mb-3">
                <div className="contact-icon linkedin">
                  <span className="d-block font-serif font-italic">
                    LinkedIn
                  </span>
                  <a
                    className="display-6 font-weight-bold"
                    href="https://www.linkedin.com/in/sierra-spencer-27904718b"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Sierra Spencer
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form-section my-3">
          <div className="container-fluid">
            <div className="row my-3">
              <div className="col-md-7">
                <div className="px-3 px-md-5 py-5 bg-darkblue h-100 text-white">
                  <h2 className="text-center">Leave Us a Message</h2>
                  <p className="pb-3 text-center">
                    Please enter your details below.
                  </p>
                  <Form
                    form_name="contact"
                    form_id="5dd5f8dd28afbd0cd50c2a21"
                    method="post"
                  >
                    <div className="form-row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            name="first_name"
                            id="first_name"
                          />
                          <label htmlFor="first_name">First Name</label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            required=""
                            name="last_name"
                            id="last_name"
                          />
                          <label htmlFor="last_name">Last Name</label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="email"
                            required=""
                            name="email"
                            id="email"
                          />
                          <label htmlFor="email">Email address</label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="tel"
                            name="phone"
                            id="phone"
                          />
                          <label htmlFor="phone">Phone (optional)</label>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <textarea
                        className="form-control h-auto"
                        required=""
                        name="message"
                        id="message"
                      />
                      <label htmlFor="message">Message</label>
                    </div>
                    <div className="form-group">
                      <div className="centered-captcha mb-3">
                        <div className="webriq-recaptcha" />
                      </div>
                    </div>
                    <div className="form-group mb-4">
                      <button
                        className="btn btn-primary btn-block"
                        type="submit"
                      >
                        Send Message
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="col-md-5 pl-md-0">
                <div className="bg" />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage

export const indexPageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
